import React, { useRef, useState } from 'react'
import './Menu.scss'
import { Link, useNavigate, useLocation} from 'react-router-dom'
import Modal from 'antd/es/modal/Modal'
import { findCarByVincode } from '../../redux/CarSlice'
import { useDispatch } from 'react-redux'


const Menu = ({scrollToComponent}) => {
  const [isModalOpen, setIsModalOpen] = useState()
  const [vincode, setVincode] = useState()
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const location = useLocation()

  const findCar = () => {
		dispatch(findCarByVincode(vincode))
		navigate('/vincode-responce')
	}


  return (
	<div className='menu' >
    <div className="menu_inner">
      <Link to='/' className="logo_block">
        <img src='/assets/logo.png' alt='logo' className='menu_logo'/>
      </Link>
    <div className="menu_content">
      <Link to='/' className="menu_item" style={{textDecoration: 'none', color: 'inherit'}}>Главная</Link>
       <Link to='/catalogue' className="menu_item" style={{textDecoration: 'none', color: 'inherit'}}>Каталог</Link>
       {location.pathname === '/' ? <div className="menu_item" onClick={() => scrollToComponent('inStock')}>В наличии</div> : null}
       {location.pathname === '/' ? <Link to='/' className="menu_item" onClick={() => scrollToComponent('discounts')} style={{textDecoration: 'none', color: 'inherit'}}>Акции</Link> : null}
       <div className="menu_item" onClick={() => setIsModalOpen(true)}>Car Tracker</div>
       <div className="menu_item" onClick={() => scrollToComponent('contacts')}>Контакты</div>
       <div className="menu_item">+996 (500) 39-09-91</div>
    </div>
    </div>

      <Modal 
		    width={600}
        height={300}
        onCancel={() =>  setIsModalOpen(false)}
        style={{ borderRadius: '10px'}}
        cancelButtonProps={{style: { display: 'none', color: 'whitesmoke' }}}
        okButtonProps={{ style: { display: 'none' } }}
        className='modal'
		    open={isModalOpen}>
				<form className="modal" onSubmit={e => e.preventDefault()}>
					<div className="modal_logo">
						<img src='/assets/logo.png' className='modal_logo_img'/>
					</div>
					<div className="modal_title">ВВЕДИТЕ VIN-CODE СВОЕЙ МАШИНЫ</div>
					<input required type="text" placeholder='VIN-code' onChange={(e) => setVincode(e.target.value)} className='input_modal'/>
					<button className='modal_btn' onClick={() => findCar()}>Найти</button>
				</form>
			
		</Modal>
    
  </div>
  )
}

export default Menu
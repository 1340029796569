import React, { useState } from 'react';
import  './MobileHeader.scss'
import { Modal } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppstoreOutlined, FileDoneOutlined, HomeOutlined, NodeIndexOutlined, PhoneOutlined, TagOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { findCarByVincode } from '../../redux/CarSlice';

const MobileHeader = ({scrollToComponent}) => {
    const [isModalOpen, setIsModalOpen] = useState()
    const [carTrackerModal, showCarTrackerModal] = useState()
    const [vincode, setVincode] = useState()
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const location = useLocation()

  const findCar = () => {
		dispatch(findCarByVincode(vincode))
		navigate('/vincode-responce')
	}

    return(
    <div className='mobile_header'>
       <div className="mobile_header_inner">
           <div className="logo_block">
              <img src='/assets/logo.png' alt='logo'/>
            </div>
            <div className="mobile_menu" onClick={() => setIsModalOpen(true)}>
                <div className=''></div>
                <div></div>
            </div>
       </div>
         <Modal 
		    width={400}
			height={280}
			onCancel={() =>  setIsModalOpen(false)}
			style={{ borderRadius: '10px', width: '90%', margin: '0 auto'}}
			cancelButtonProps={{style: { display: 'none', color: 'whitesmoke' }}}
			okButtonProps={{ style: { display: 'none' } }}
			className='mobile_menu_modal'
			open={isModalOpen}>
				<form className="mobile_header" onSubmit={e => e.preventDefault()}>
			        <Link to='/' className="mobile_menu_item" style={{textDecoration: 'none', color: 'inherit'}}><div className='slash'><HomeOutlined /></div>Главная</Link>
                    <Link to='/catalogue' className="mobile_menu_item" style={{textDecoration: 'none', color: 'inherit'}}><div className='slash'><AppstoreOutlined /></div>Каталог</Link>
                    { location.pathname === '/' ? <div className="mobile_menu_item" onClick={() => {scrollToComponent('inStock'); setIsModalOpen(false)}}><div className='slash'><FileDoneOutlined /></div>В наличии</div> : null}
                    { location.pathname === '/' ? <div className="mobile_menu_item" onClick={() => {scrollToComponent('discounts'); setIsModalOpen(false)}}><div className='slash'><TagOutlined /></div>Акции</div> : null}
                    <div className="mobile_menu_item" onClick={() => {setIsModalOpen(false); showCarTrackerModal(true)}}><div className='slash'><NodeIndexOutlined /></div>Car Tracker</div>
                    <div className="mobile_menu_item" onClick={() => {scrollToComponent('contacts'); setIsModalOpen(false)}}> <div className='slash'><PhoneOutlined /></div>Контакты</div>
				</form>
		</Modal>
        <Modal 
		width={400}
        height={320}
        onCancel={() =>  showCarTrackerModal(false)}
        style={{ borderRadius: '10px'}}
        cancelButtonProps={{style: { display: 'none', color: 'whitesmoke' }}}
        okButtonProps={{ style: { display: 'none' } }}
        className='mobile_modal'
		    open={carTrackerModal}>
				<form className="modal" onSubmit={e => e.preventDefault()}>
					<div className="modal_logo">
						<img src='/assets/logo.png' alt="logo" className='modal_logo_img'/>
					</div>
					<div className="modal_title">ВВЕДИТЕ VIN-CODE СВОЕЙ МАШИНЫ</div>
					<input required type="text" placeholder='VIN-code' onChange={(e) => setVincode(e.target.value)} className='input_modal'/>
					<button className='modal_btn' onClick={() => findCar()}>Найти</button>
				</form>
			
		</Modal>
    </div>

)};

export default MobileHeader;
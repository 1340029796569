
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Suspense, lazy } from 'react';
import CataloguePage from './Pages/CataloguePage/CataloguePage';

const Home = lazy(() => import('./Pages/Home/Home'))
const CarDetails = lazy(() => import('./Pages/CarDetails/CarDetails'))
const VincodeResponce = lazy(() => import('./Pages/VincodeResponce/VincodeResponce'))

function App() {

  return (
    <BrowserRouter>
      <Suspense>
          <Routes>
            <Route path='' element={<Home />} />
            <Route path='/catalogue' element={<CataloguePage />} />
            <Route path='/car/:id' element={<CarDetails />} />
            <Route path='/vincode-responce' element={ <VincodeResponce /> } />
          </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

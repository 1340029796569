import React, { Suspense, lazy, useEffect, useRef, useState } from 'react'


const LazyImages = ({car}) => {

const CatalogueItem = lazy(() => import('../../Components/Catalogue/CatalogueItem'))
	const [isInView, setIsInView] = useState(false);
	const ref = useRef(null)

	  function onIntersection(entries) {
		setIsInView(true);
	}


	useEffect(() => {
		let observer = new IntersectionObserver(onIntersection, {});
		if(ref.current){observer.observe(ref.current)}
		return () => {
			observer.disconnect()
		}
	}, [])

  return (
	<div ref={ref}>
		<Suspense>
		{isInView ? <CatalogueItem car={car}/> : null}	
		</Suspense>
		
	</div>
  )
}

export default LazyImages
import axios from 'axios'

const BASE_URL = 'http://api.cncars.kg/api/v1/'

export const publicRequest = axios.create({
	baseURL: BASE_URL
})

export const executeScroll = (ref) => {
		ref.current.scrollIntoView()
}



	export function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

	//  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


       export function handleResize() {
           getWindowDimensions();
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);

        }





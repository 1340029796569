import { FacebookOutlined, HomeOutlined, InstagramOutlined, MailOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import './Footer.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import  AOS  from 'aos';



const Footer = () => {
	useEffect(() => {
		 AOS.init({
			 delay: 100,
		 });  
	}, [])


	const contacts = useSelector(state => state.cars.contacts)
  return (
	<div className='footer'>
		<div className="footer_logo" data-aos="zoom-in-down">
			<div className='footer_logo_img'>
				<img src="/assets/logo.png" alt="" className="logo_img" />
			</div>
			<div className="empty_blocks">
				<div className='block'></div>
				<div className='block'></div>
				<div className='block'></div>
			</div>
		</div>
		<div className='footer_content'>
	        
			<div className="footer_block" data-aos="zoom-in-right">
				<div className="h3_contacts"  >Контакты</div>
				<div className="divider" style={{marginLeft: '10px', width: '60px'}}></div>
				<div className="footer_item">
					<div className="footer_item_text">
						<PhoneOutlined style={{marginRight: '8px', color: 'rgb(215, 93, 27)'}}/>
						Телефон:
					</div>
					<div className="footer_item_detail">{contacts && contacts[0]?.phone}</div>
				</div>
				<div className="footer_item">
					<div className="footer_item_text"><HomeOutlined style={{marginRight: '10px', color: 'rgb(215, 93, 27)'}}/> Адрес: </div>
					<div className="footer_item_detail">{contacts && contacts[0]?.address}</div>

				</div>
				<div className="footer_item">
					<div className="footer_item_text"> <MailOutlined style={{marginRight: '10px', fontSize: '14px', color: 'rgb(215, 93, 27)'}}/> Email:</div>
					<div className="footer_item_detail">{contacts && contacts[0]?.email}</div>
				</div>
				<div className="social_networks_icons">		
					<Link to={contacts && `${contacts[0]?.instagram}`}><InstagramOutlined style={{marginRight: '20px'}} className='network_icon' data-aos="zoom-in-down" data-aos-duration="1000"/></Link>
					<Link to={contacts && `${contacts[0]?.whatsapp}`}><WhatsAppOutlined style={{marginRight: '20px'}} className='network_icon' data-aos="zoom-in-down" data-aos-duration="1500"/></Link>
					<Link to={contacts && `${contacts[0]?.facebook}`}><FacebookOutlined className='network_icon' data-aos="zoom-in-down" data-aos-duration="2000"/></Link>
				</div>	
			</div>

			<div className="map" data-aos="zoom-in-left">
				<iframe className='iframe_map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2924.018267794073!2d74.59011817600742!3d42.87245857114991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec82748aed9cd%3A0xcdfa333a8172780f!2zMTQ2INGD0LsuINCi0L7QutGC0L7Qs9GD0LvQsCwg0JHQuNGI0LrQtdC6!5e0!3m2!1sru!2skg!4v1683196988399!5m2!1sru!2skg" width="600" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
			</div>
		</div>
		<div className="bottom_footer">Все права защищены</div>
	</div>
  )
}

export default Footer
import React, { Suspense, lazy, useEffect, useRef, useState } from 'react'
import './CataloguePage.scss'
// import CatalogueItem from '../../Components/Catalogue/CatalogueItem';
import Footer from '../../Components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import AOS from 'aos';
import Filter from '../../Components/Filter/Filter';
import Menu from '../../Components/Menu/Menu';
import { getCarDetails } from '../../redux/CarSlice';
import MobileHeader from '../../Components/MobileHeader/MobileHeader';
import "aos/dist/aos.css";
import LazyImages from '../../Components/LazyImage/LazyImage';
import { Link } from 'react-router-dom';
import '../Home/Home.css'
import '../../Components/Slider/Slider.scss'


const CataloguePage = () => {
	const cars = useSelector(state => state.cars.cars)
	const dispatch = useDispatch()
	const contactsRef = useRef()


	 function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

	const scrollToComponent = () => {
		  contactsRef.current.scrollIntoView()
		}
	
		useEffect(() => {
		 AOS.init({
			 delay: 100,
			 duration: 1000
		 });
	}, [])


  return (
	<div className='catalogue_page'>
		{ windowDimensions.width < 900
                ? <MobileHeader scrollToComponent={scrollToComponent}/>
                : <div data-aos="fade-down"><Menu scrollToComponent={scrollToComponent}/></div>
		}
     <div data-aos="fade-down"><Filter/></div>
		
		<div className="catalogue_banner">
			<img src="/assets/banner.svg" alt="" className="banner_img" data-aos="zoom-out" data-aos-duration='3000'/>
			<div className="catalogue_main_content">	
				<div className="catalogue_main" data-aos="zoom-in-up">
					<Suspense fallback='Loading...'>
						{cars?.map(car => (
							<LazyImages 
								car={car} 
								onClick={() => dispatch(getCarDetails(car.id))}
							/>										
						))}
						</Suspense>	
				</div>
			</div>
		</div>
		<div ref={contactsRef}>
			<Footer />
		</div>
		<ScrollToTop />
		<div className="whatsapp">
			<Link to='https://wa.me/+996500390991'>
				<img src='/assets/whatsapp.png' className='whatsapp_icon'/>
			</Link>
		</div>
	</div>
  )
}

export default CataloguePage
import React, { useState } from 'react'
import './Filter.scss'
import { Select} from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { filterCars } from '../../redux/CarSlice';

const Filter = ({background}) => {

	const categories = useSelector(state => state.cars.categories)

	const [brand, setBrand] = useState(Array.isArray(categories) && categories.length > 0 ?  categories[0]?.id : null )
	const [model, setModel] = useState(Array.isArray(categories) && categories.length > 0  ? categories[0]?.children[0]?.id : null)
	const [price, setPrice] = useState()
	const [bodyType, setBodyType] = useState('Седан')
	const [year, setYear] = useState()
	const dispatch = useDispatch()

	const findCar = () => {
		 dispatch(
		 	filterCars({
		 		brand,
		 		model,
		 		body_type: bodyType,
		 		price,
		 		production_year: year
		 	})
		 )
	}


	
  return (
	<div className="filter_wrapper" style={background ? background : null}>
		<div className='filter' >
		
		<div className="filter_item">
			<div className="filter_name">Бренд:</div>
			<Select
			    className='filter_select'
			    suffixIcon={<CaretDownFilled style={{color: 'white'}}/>}
				defaultValue={brand}
				onChange={(e) => setBrand(e)}
				bordered={false}
				options={categories?.map(it => ({value: it?.id, label: it?.name}))}
			/>
		</div>
		<div className="filter_item">
			<div className="filter_name">Модель:</div>
			<Select
			    className='filter_select'
			    suffixIcon={<CaretDownFilled style={{color: 'white'}}/>}
				defaultValue={categories?.find(it => it?.id === brand)?.children[0]?.id}
				onChange={e => setModel(e)}
				bordered={false}
				options={categories?.find(it => it?.id === brand)?.children?.map(el => ({value: el?.id, label: el?.name}))}
			/>
		</div>
		<div className="filter_item">
			<div className="filter_name">Привод:</div>
			<Select
				className='filter_select'
			    suffixIcon={<CaretDownFilled style={{color: 'white'}}/>}
				defaultValue="Седан"
				onChange={(e) => setBodyType(e)}
				bordered={false}
				options={[
					{ value: 'Седан', label: 'Седан' },
					{ value: 'Универсал', label: 'Универсал' },
					{ value: 'Минивен', label: 'Минивен' },
					{ value: 'Хэтчбек', label: 'Хэтчбек', },
					{ value: 'Купе', label: 'Купе' },
					{ value: 'Кроссовер', label: 'Кроссовер', },
				]}
			/>
		</div>
		<div className="filter_item">
			<div className="filter_name">Год:</div>
			<input type='text' className='filter_input' onChange={(e) => setYear(e.target.value)}/>
		</div>
		<div className="filter_item">
			<div className="filter_name">Цена от:</div>
			<input type='text' className='filter_input' onChange={(e) => setPrice(e.target.value)}/>
		</div>
		<div className="filter_item">
			<Link to='/catalogue' className='filter_btn' onClick={() => findCar()}>Найти</Link>
		 </div>
	</div>
	</div>
	
  )
}

export default Filter